@import "~@/erp/styles/variables/variables.scss";









































































































































.table-container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .content-wrapper {
    flex: 1;
    overflow: auto;
  }
  .btn-wrapper {
    height: 40px;
    margin-top: 10px;
    text-align: center;
    .el-button {
      margin: 0 8px;
    }
  }
}
