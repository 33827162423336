@import "~@/erp/styles/variables/variables.scss";



















































































































































































































































.vertical {
  display: inline-block;
  height: 20px;
  width: 5px;
  background-color: #409eff;
  vertical-align: middle;
}
.new-btn {
  width: 100%;
  border-top: none;
  color: #409eff;
}

.el-form-item__error {
  display: none !important;
}
.el-form-item--small.el-form-item {
  margin-bottom: 0px !important;
}

.el-table {
  margin-top: 10px;
}
h3 {
  margin-top: 5px;
}
.h3 {
  margin-top: 40px;
}
